if($('.section-people').length) {
  $filterPeople = $('.section-people .filter-people');

  $('.section-people').on('click','.filter-people',function(e) {
    e.stopImmediatePropagation();
    var $this = $(this);
    $filterPeople.removeClass('active');
    $this.addClass('active');
    var filterID = $this.attr('data-typID');
    var pageID = $this.attr('data-pageID');
    var dataURL = $this.attr('data-url') + '/theme/includes/block-people-filter.cfm?pageID=' + pageID + '&filterID=' + filterID;
    $.ajax({
      url: dataURL,
      success: function(data, textStatus, jqXHR) {
          $('.section-people .lc-people-columns').html(data);
      },
      error: function(jqXHR, textStatus, errorThrown) {
          // error handling
          console.log('error');
      }
    });
  });
  
};