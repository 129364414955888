var globals = require('../../js/globals.js');

// Load hemstadning by default on slider form
if ($('.lc-booking-form-pri-slider').length) {
	var loadURL = globals.siteURL + '/tw/pri/forms/hemstadning/slider-form.cfm';
	$('.lc-booking-form-pri-slider').load(loadURL);
}

if ($('.lc-booking-form-pri').length) {
	var $form = $('.lc-booking-form-pri');
	var currentService = $('.lc-booking-form-pri').attr('data-service');
	var showPrice = $('.lc-booking-form-pri #f_showPrice').val();
	var zipCode = $('.lc-booking-form-pri #c__delivery_address__postal_code').val();
	var loadURL = globals.siteURL + '/tw/pri/forms/' + currentService + '/form-step1.cfm?p=' + showPrice + '&z=' + zipCode;

	// Load part 1 in form
	$('.lc-booking-form-pri #lc-booking-form-pri-step1').load(loadURL);
}



	


