var globals = require('../../js/globals.js');
// Init
if($('.happypercentage').length) {
    var jsonHiUrl = globals.siteURL + '/theme/includes/block-lequest-data.cfm';
    $.getJSON({
        url: jsonHiUrl,
        success: function(data, textStatus, jqXHR) {
            console.log('mod 5 data: ' + data);
            $('.happypercentage._1').html(data[0]);
            $('.happypercentage._2').html(data[1]);
            $('.happypercentage._3').html(data[2]);
            $('.happypercentage._4').html(data[3]);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('error mod 5: ' + textStatus);
        }
    });
}