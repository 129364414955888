// Init
$formNewsletter = $('#form_lc_newsletter');
$formNewsletter.find('.form-captcha').hide(); // Hide Captcha
$formNewsletter.find('.form-wait').hide(); // Hide loader
$formNewsletter.find('.form-answer').hide(); // hide answer

// on submit click - Check captcha
$formNewsletter.on('click', '#f_submit', function(e) {
    e.stopImmediatePropagation();
    // Is the form valid?
    if ($formNewsletter.valid()) {
        // show the captcha
        $formNewsletter.find('.form-captcha').show(); // show captcha
        $formNewsletter.find('.form-fields').hide(); // hide all fields
        $formNewsletter.find('.form-answer').hide(); // hide answer
        // Loop to check the captcha response
        var checkCaptcha = setInterval(function() {
            if (grecaptcha.getResponse()) {
                clearInterval(checkCaptcha);
                formNewsletterSubmit();
            }
        }, 200);
    } 
});

// Submit the form
function formNewsletterSubmit () {
    // Show & hide 
    $formNewsletter.find('.form-intro').hide(); // hide intro
    $formNewsletter.find('.form-fields').hide(); // hide all fields
    $formNewsletter.find('.form-captcha').hide(); // hide captcha
    $formNewsletter.find('.form-wait').show(); // show loader
    // send the form
    var postData = $formNewsletter.serializeArray();
    var dataURL = $formNewsletter.attr('data-url') + '/theme/includes/block-form-newsletter-submit.cfm';
    $.ajax({
        data: postData,
        type: 'post',
        url: dataURL,
        dataType: "json",
        success: function(data, textStatus, jqXHR) {
            $formNewsletter.find('.form-wait').hide();
            grecaptcha.reset();
            switch(data[0]) {
                case '0':
                    $formNewsletter.find('.form-answer').html('<div class="col-12">' + data[1] + '</div>').show();
                    break;
                default:
                    // Already exist
                    $('#f_email').val('');
                    $formNewsletter.find('.form-intro').show();
                    $formNewsletter.find('.form-fields').show();
                    $formNewsletter.find('.form-answer').html('<div class="col-12">' + data[1] + '</div>').show();
                    break;
            }            
        },
        error: function(jqXHR, textStatus, errorThrown) {
            // error handling
            console.log('error');
        }
    });
}

// Validator email complete
jQuery.validator.addMethod("validate_email",function(value, element) {
    if(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value))
    { return true;} else { return false; }    
},"Please enter a valid Email.");

// Validator
var validatorNewsletter = $formNewsletter.validate ({
    rules: {
        f_email: {required: true, email: true, validate_email: true}
    },
    highlight: function (element) {
        $(element).closest('.field').removeClass('has-success').addClass('has-danger');
    },
    unhighlight: function (element) {
        $(element).closest('.field').removeClass('has-danger');
    },
    errorPlacement: function(error,element) {
        return true;
    }
});



