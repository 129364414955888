// Bootstrap wants jQuery global
window.jQuery = $ = require('jquery');

// Bootstrap wants Tether global
window.Tether = require('../../node_modules/tether/dist/js/tether.js');

// Load libraries
// Bootstrap;
var bootstrap = require('../../node_modules/bootstrap/dist/js/bootstrap.js');
var slickslider = require('../../node_modules/slick-carousel/slick/slick.js');
var sneakpeek = require('sneakpeek');
var aos = require('aos');
var Cookies = require('js-cookie');
var bootstrapselect = require('bootstrap-select');
var rellax = require('rellax');
var moment = require('moment');
require('moment/locale/sv');
window.moment = moment;



var objectFitImages = require('object-fit-images');


//var underscore = require('../../node_modules/underscore');
var bscalendar = require('bootstrap-calendar');

$(document).ready(function () {

    var globalVariables = require('../mod/global-variables/global-variables.js');

    objectFitImages();

    // Convert inline js, to not be render blocking.
    //   $('#somescript').replaceWith('<script>' + $('#somescript').html() + '</script>');

    var viewportWidth = $(window).width();

    var nativeSelect = false;
    if (viewportWidth > 768) {
        var rellaxpara = new rellax('.rellax');
        nativeSelect = true;
    }

    

    $('a[href="#"]').click(function(e) {
        e.preventDefault ? e.preventDefault() : e.returnValue = false;
    });

    // Select picker config
    $('.lc-selectpicker').selectpicker(
        {
            noneSelectedText: 'Nothing selected',
            noneResultsText: 'No results matched {0}',
            countSelectedText: function (numSelected, numTotal) {
                return (numSelected == 1) ? "{0} item selected" : "{0} items selected";
            },
            maxOptionsText: function (numAll, numGroup) {
                return [
                    (numAll == 1) ? 'Limit reached ({n} item max)' : 'Limit reached ({n} items max)',
                    (numGroup == 1) ? 'Group limit reached ({n} item max)' : 'Group limit reached ({n} items max)'
                ];
            },
            selectAllText: 'Select All',
            deselectAllText: 'Deselect All',
            doneButton: false,
            doneButtonText: 'Close',
            multipleSeparator: ', ',
            styleBase: 'btn',
       //     style: 'btn-select',
            size: 'auto',
            title: null,
            selectedTextFormat: 'values',
            width: false,
            container: false,
            hideDisabled: false,
            showSubtext: false,
            showIcon: true,
            showContent: true,
            dropupAuto: true,
            header: false,
            liveSearch: false,
            liveSearchPlaceholder: null,
            liveSearchNormalize: false,
            liveSearchStyle: 'contains',
            actionsBox: false,
            iconBase: 'glyphicon',
            tickIcon: 'glyphicon-ok',
            showTick: false,
            template: {
                caret: '<span class="caret"></span>'
            },
            maxOptions: false,
            mobile: nativeSelect,
            selectOnTab: false,
            dropdownAlignRight: false,
            windowPadding: 0
        }
    );


    // Init aos. (Animate on scroll)
    aos.init({
        offset: 50,
        delay: 0,
        duration: 850
    });

    // Hide with sneakpeek on scroll
    sneakpeek(document.getElementById("main-navigation"));
    // Hide any open dropdowns. WHen menu is hidden
    sneakpeek.emitter.on('hide', function () {
        $('.dropdown-wide').collapse('hide');
    });


    // Booking form slider JS - super simple.
    $('.bookingform-radio-switch a').on('click touchstart', function (e) {

        // Toogle active
        $(this).parent().find('a').removeClass('active');
        $(this).toggleClass('active');

        // Hide all
        $($(this).data("parent")).removeClass('slide-visible');

        // Show one
        $($(this).data("target")).addClass('slide-visible');
    });

    // Init booking slide
    $('.booking-slide').slick({
        arrows: false,
        autoplay: true,
//        speed: 1000,
        customPaging: function (slider, i) {
            return $('<button type="button" data-role="none" role="button" tabindex="0" />').text($('.booking-slide').find('div[data-slick-index=' + i + ']').data('title'));
        },

        fade: true,
        dots: true,

        infinite: true,
        autoplaySpeed: 70000,
        speed: 160,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    });


    // Slick slider trying out.
    $('.slickoffers').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });


    var startbookinganiTimer = '';
    // Start booking slide on mouse leave.
    $('.booking-slide').mouseleave(function () {
        clearTimeout(startbookinganiTimer);
        startbookinganiTimer = setTimeout(function () {
            // Start slider
            $('.booking-slide').slick('slickPlay');
            //  console.log('startit');
        }, 200);
    });


    /**
     * Menu script for top navigation.
     */

    // TODO: refactor into own js module.
    // TODO: Embed in an object/class
    function resetDropdownFolds() {
        // Reset .active classes in the dropdown-wide
        $('.dropdown-wide').find('.active').removeClass('active');

        // Hide all icons
        $('.foldicon').hide();

        // Hide all L3 folds.
        $('.dropdown-sub').hide();


        $('.dropdown-wide').find('.nav-dropdown-l2').find('a.nav-link').each(function () {
            // Check if active trail exitst
            if ($(this).hasClass('active-menu-trail')) {

                // Show active-menu-trail icon
                $(this).closest('.dropdown-wide').find('#l3-' + $(this).attr('data-iconid')).show();

                // Show active-menu-trail fold-down
                $(this).closest('.dropdown-wide').find('#foldicon' + $(this).attr('data-iconid')).show();

                // If no active fold found - show first.
            } else {

                // Check that none of the others a has active menu trail.
                if($(this).closest('ul').find('a.active-menu-trail').length == 0){
                    // Change first link to active
                    $(this).closest('ul').find('li').first().find('a').addClass('active');

                    // Show first icon
                    $(this).closest('.dropdown-wide').find('.foldicon').first().show();

                    // Show first fold-down
                    $(this).closest('.dropdown-wide').find('.dropdown-sub').first().show();
                }

            }
        });
    }


    var foldTimer;
    // Show top-menu (level 1) dropdowns on hover.
    $('#topmenu').find('li.nav-item').find('a').mouseenter(function (event) {

        // Add active class on "hover"
        $('#topmenu').find('li.nav-item').find('.nav-link').removeClass('active');
        $(this).addClass('active');

        clearTimeout(foldTimer);
        clearTimeout(hidedropdownTimer);

        // If alreay open.
        if ($('.dropdown-wide.collapse.show').length > 0) {

            // Delay: If already visible show after 150ms.
            if ($(this).parent().hasClass('lc-dropdown-wide')) {

                var waittriggerID = $(this).data("target");
                if (waittriggerID !== undefined) {
                    clearTimeout(foldTimer);
                    foldTimer = setTimeout(function () {
                        resetDropdownFolds();
                        $(waittriggerID).collapse('show');
                        // Pause slider
                        $('.booking-slide').slick('slickPause');
                    }, 50);
                }
            }
            // Hide last fold
            $('.dropdown-wide').collapse('hide');
        } else {
            if ($(this).data("target") !== undefined) {
                resetDropdownFolds();
                $($(this).data("target")).collapse('show');
                // Pause slider
                $('.booking-slide').slick('slickPause');
            }
        }

        event.preventDefault();
    });

    // Show top-menu (level 2) dropdowns on hover.
    $('.nav-dropdown-l2').find('li.nav-item').find('a').mouseenter(function (event) {

            // Remove hover classes from other l2 buttons.
            $(this).closest('.dropdown-wide').find('.nav-dropdown-l2').find('a.nav-link').removeClass('active');

            // Add hover class to this l2 button
            $(this).addClass('active');

            // Show correct icon
            $('.foldicon').hide();
            $('#foldicon' + $(this).attr('data-iconid')).show();

            // Hide all fold
            $(this).closest('.dropdown-wide').find('.dropdown-sub').hide();

            // Show hover fold
            $('#l3-' + $(this).attr('data-iconid')).show();

            event.preventDefault();
        }
    );

    // Dropdowns mouse leave behaviours
    var hidedropdownTimer = '';
    // Hide dropdown on li exit if, if cursor is not above the dropdown. Timeout due to IE.
    $('#topmenu').mouseleave(function (event) {

        clearTimeout(hidedropdownTimer);
        hidedropdownTimer = setTimeout(function () {
            if ($('.dropdown-wide:hover').length == 0) {
                $('.dropdown-wide').collapse('hide');
                $('.booking-slide').slick('slickPlay');
            }
        }, 200);
        event.preventDefault();
    });

    // Hide dropdown on div exit, if cursor is not above the top menu. Timeout due to IE.
    $('.dropdown-wide').mouseleave(function (event) {
        clearTimeout(hidedropdownTimer);
        hidedropdownTimer = setTimeout(function () {
            if ($('#topmenu:hover').length == 0) {
                $('.dropdown-wide').collapse('hide');
            }
        }, 200);
        event.preventDefault();
    });


    // TODO: refactor into own js module.
    /**
     * Mobile menu script for top navigation.
     */
    // Reset on menu-active-trail on open.
    $('button.navbar-toggler.collapsed').on('click', function (event) {
        // Reset active menu trail
        $('#mobile-menu').find('.active').removeClass('active');
        $('#mobile-menu').find('.collapse').collapse('hide');

        // Add active to menu trail, and open.
        $('#mobile-menu').find('.active-menu-trail').addClass('active');
        $('#mobile-menu').find('.active-menu-trail').next().addClass('show');
    });

    // Mobile-Login-menu collapse. Show the fixed bottom buttons. Due to IOS scroll problems.
    $('#mobile-menu').on('show.bs.collapse', function (event) {
        $('#mobile-menu-bottombar').collapse('show');
    });
    $('#mobile-menu').on('hide.bs.collapse', function () {
        $('#mobile-menu-bottombar').collapse('hide');
    });

    // Bootstrap v4 collapsable navigation nested extension by Markus.
    $('#mobile-menu').find('.nav-link').click(function () {

        if ($(this).hasClass('active')) {
            // Close
            $(this).removeClass('active');
            $(this).next('.collapse').collapse('hide');
        } else {
            // Open
            $(this).closest('ul').find('.active').removeClass('active');
            $(this).addClass('active');

            $('#mobile-menu :not(:has(.active))').find('.collapse').collapse('hide');
            $(this).next().collapse('toggle');
        }
    });

    $('#mobile-menu').on('hide.bs.collapse', function () {
        $('#main-navigation').removeClass('transp-nav');
        //   $('.mobile-slogan').show();
    });
    $('#mobile-menu').on('show.bs.collapse', function () {
        $('#main-navigation').addClass('transp-nav');
        // $('.mobile-slogan').hide();
    });


    /**
     * Footer menu tree expand/hide.
     */
    // Init expansion for lc-tabs
    $('.footer-nav').find('ul').collapse({
        'toggle':false
    });

    // Show all active
    $('.footer-nav').find('a.active').closest('ul').collapse('show');

    $('.footer-nav').find('li.nav-item').find('a').click(function (event) {

        if ($(this).hasClass('active')) {
            // Close
            $(this).removeClass('active');
            $(this).next('ul').collapse('hide');
            // Reset child uls
            $(this).next('ul').find('ul').collapse('hide');
            $(this).next('ul').find('a').removeClass('active');
        } else {
            // Close others
            $(this).closest('div').find('ul').not([
//               $(this).parentsUntil('.div').parents('ul')
                $(this).parentsUntil('div')
            ]).collapse('hide');

            $(this).closest('div').not([
//                $(this).parentsUntil('.div')
                console.log($(this).parentsUntil('div').find('a'))
            ]).find('ul').find('a').removeClass('active');

            $(this).parentsUntil('.footer-nav').parents('ul').collapse('show');

            // Open
           // $(this).addClass('active');
            $(this).next('ul').collapse('show');
        }

        /*
        // Hide all others
        $('.footer-nav').find('.nav-item').removeClass('active');

        // Show parents for this
        $(this).parentsUntil('.footer-nav').('background-color','yellow');

        if ($(this).closest('.nav-item').hasClass('active')) {
            $(this).closest('.nav-item').removeClass('active');
        } else {
            // Add class
            $(this).closest('.nav-item').addClass('active');
        }

        */
        //event.preventDefault();
    });



    /**
     * Script for expand/hide sections.
     */

    // Helper function to scrollIntoView
    function scrollIntoViewIfNeeded(target) {
        var newPos = target.offset();

        if((newPos.top + 100  ) > ($(window).scrollTop() + $(window).height())){
            window.scrollTo(0,newPos.top - ($(window).height() / 2.5 ));
        }
    }
    
    $('.lc-btn-expand').on( "click touchevent", function() {
        $('.lc-btn-expand').removeClass('active-menu-trail');
        $(this).addClass('active-menu-trail');
        // Get button order
        var tabOpenIndex = [].indexOf.call($('.lc-btn-expand'), this);

        var expandTarget = $('.lc-tab-expand:eq('+ tabOpenIndex +')');

        // Hide all expandable tabs, expect the one opening, if already opened.
        $('.lc-tab-expand').not(expandTarget).removeClass('lc-tab-expand-open');

        // Remove classes for button close animation
        $('.lc-tab-expand').removeClass('lc-tab-expand-close');

        // Show selected expandable tab
        expandTarget.addClass('lc-tab-expand-open');

        scrollIntoViewIfNeeded(expandTarget);
    });

    $('.LC-btn-expand').on( "click", function() {
        $('.LC-btn-expand').removeClass('active-menu-trail');
        $(this).addClass('active-menu-trail');
        // Get button order
        var tabOpenIndex = [].indexOf.call($('.LC-btn-expand'), this);
        // Hide all expandable tabs
        $('.lc-tab-expand').collapse('hide');
        // Show selected expandable tab
        $('.lc-tab-expand:eq('+ tabOpenIndex +')').collapse('show');
    });

    $('.lc-tab-btn-close').on( "click touchevent", function() {
        $(this).closest('.lc-tab-expand').addClass('lc-tab-expand-close');

        var closeTarget = $(this).closest('.lc-tab-expand');

        // Timeout on close
        setTimeout(function(){
            closeTarget.removeClass('lc-tab-expand-open');
            closeTarget.removeClass('lc-tab-expand-close');
            $('.lc-btn-expand').removeClass('active-menu-trail');
            aos.refresh();
        }, 500);
    });

    









    function ShowImage(imgSrc) {
        $("#imgFull").attr("src", imgSrc);
        $("#imgFull").load(function(){  //This ensures image finishes loading
            //alert($("#imgFull").height());
            $("#imgFullView").width($("#imgFull").width());
            $("#imgFullView").height($("#imgFull").height());
            show_modal('imgFullView', true);
        }); 
    }

    $('[data-youtube]').click(function () {
        var videoID = $(this).attr('data-youtube');
        var img = $(this).find('img'); 
        videoHeight = $(img).height();
        var src = '//www.youtube.com/embed/'+ videoID + '?autoplay=1';
        var videoTarget = $(this).attr('data-target'); 
        var iframe = '<iframe id="youtube" width="100%" height="' + videoHeight + '" frameborder="0" src="'+src+'" allowfullscreen></iframe>';
        $(this).hide();
        $("." + videoTarget).html(iframe);
        return false; 
    });


    /**
     *  Cookie information
     */
    // Get cookie
    var cookieInfoClosedByUser = Cookies.get('cookieInfoClosedByUser');

    // If cookie not found, show cookie information.
    if(cookieInfoClosedByUser != 'infohidden' ) {
        // Add show class to cookie info div.
        $('.cookie-info').addClass('cookie-info-show');
    }

    // Eventhandler for cookie close
    $('.cookie-close').on('click touchstart', function() {
        Cookies.set('cookieInfoClosedByUser', 'infohidden', { expires: 365 });
        $('.cookie-info').removeClass('cookie-info-show');
    });

    /**
     *  Important information
     */
        // Get cookie
    var importantInfoClosedByUser = Cookies.get('importantInfoClosedByUser');

    // If cookie not found, show cookie information.
    if(importantInfoClosedByUser != 'infohidden' ) {
        // Add show class to cookie info div.
        //  $('.important-info').addClass('important-info-show');
    }

    // Eventhandler for cookie close
    $('.important-info-close').on('click touchstart', function() {
//        Cookies.set('cookieInfoClosedByUser', 'infohidden', { expires: 1 });
        $('.important-info').removeClass('important-info-show');
    });


    /**
     * Script for expand/hide next element, used for expand lists and more.
     * Currently last, because we've not divided this whole document.ready into modules.
     */
    //    var expandlist = require('../mod/expandlist/block-module-_block-module-41-happyindexbkgimgwide.scss.js');
    var expandlist = require('../mod/expandlistfaq/block-module-expandlistfaq.js');
    var lcMod1_socialandnews = require('../mod/lc-mod-1-socialandnews/block-module-1-socialandnews.js');
    var lcMod5_happyindexstart = require('../mod/lc-mod-5-happyindexstart/block-module-5-happyindexstart.js');
    var lcMod33_people = require('../mod/lc-mod-33-people/block-module-lc-mod-33-people.js');
    var bloglayout38 = require('../mod/lc-mod-38-blog/block-module-38-blog.js');
    //var lcMod_41_happyindexBkg = require('../mod/lc-mod-41-happyindexbkgimgwide/block-module-41-happyindexbkgimgwide.js');
    // var lcMid42_bookingcalendar = require('../mod/lc-mod-42-bookingcalendar/block-module-42-bookingcalendar.js');
    var lcMod_39_paraimgwide = require('../mod/lc-mod-39-paraimgwide/block-module-39-paraimgwide.js');
    var lcMod_43_booking_pri = require('../mod/lc-mod-43-booking-pri/block-module-booking-pri.js');
    //var lcMod_43_booking_pri_hemstadning = require('../mod/lc-mod-43-booking-pri/block-module-booking-pri-hemstadning.js');

    var lcMod_44_plusminus = require('../mod/lc-mod-44-plusminus/block-module-44-plusminus.js');
//    var lcMod_44_plusminus = require('../mod/lc-mod-44/block-module-booking-pri-hemstadning.js');

    /**
     * User input tests - only in jQuery now, will try finding one solution that works the same in webdriver.io and locally for css.
     */
    var userInputSimulation = require('../js/userinputsimulation.js');
});